<!--
 * @Description: 商品详情页面组件
 * @Author: hai-27
 * @Date: 2020-02-16 20:20:26
 * @LastEditors: hai-27
 * @LastEditTime: 2020-03-07 21:59:26
 -->
<template>
	<div class="brands">


		<!-- 主要内容 -->
		<div class="goods width1225">
			<!-- 面包屑 -->
			<div class="breadcrumb">
				<el-breadcrumb separator-class="el-icon-arrow-right">
					<el-breadcrumb-item>
						<router-link to="/">首页</router-link>
					</el-breadcrumb-item>
					<el-breadcrumb-item>全部商品</el-breadcrumb-item>
					<el-breadcrumb-item v-if="search">搜索</el-breadcrumb-item>
					<el-breadcrumb-item v-else>分类</el-breadcrumb-item>
					<el-breadcrumb-item v-if="search">{{ search }}</el-breadcrumb-item>
				</el-breadcrumb>
			</div>
		</div>
		<div class="command-brands clear width1225">
			<div class="logo fl">
				<div></div>
				<h3>热门元器件代理商</h3>
			</div>
			<div class="items fl boxsiz">
				<a href="" class="item fl boxsiz" :title="item.Name" v-for="item in supp" :key="item.Id">
					<img :src="getUrl(item.Logo)">
				</a>
				
			</div>
		</div>
		<!-- 主要内容END -->
		<div class="commands-all width1225">
			<div class="title-div clear">
				<h3 class="title fl">所有品牌</h3>
			</div>
			<div class="brand-list">
				<div class="brand-one">
					<div class="items clear">
						<a href=" " class="item fl" :title="item.Name"  v-for="item in supp2" :key="item.Id">
							<img :src="getUrl(item.Logo)"
								class="img i-show" >
							<p class="ellipsis">{{ item.Name }}</p>
						</a>
						
					</div>
				</div>

			</div>
		</div>

</div>
</template>
<script>
import {
	mapActions
} from "vuex";
export default {
	data() {
		return {
			productNum: 1,
			dis: false, // 控制“加入购物车按钮是否可用”
			productID: "", // 商品id
			productDetails: "", // 商品详细信息
			productPicture: "", // 商品图片
			productContent: '',
			TopGoods: [],
			supp:[],
			supp2:[]
		};
	},
	created() {
		// 获取轮播图数据
		this.loadBannerList();

	},
	methods: {
		async loadBannerList() {
			
			let res = await this.postdata('/api/News/BrandList', {
				"page": 1,
				"limit": 99
			})
			this.supp2 = res.data.items
			res = await this.postdata('/api/News/SupplierList', {
				"page": 1,
				"limit": 99
			})
			this.supp = res.data.items
		},
	}
};
</script>

<style lang="less" scoped></style>
<style scoped>
.Bottomdetail {
	float: right;
	width: 960px;
}

.goods .breadcrumb {
	/* height: 50px; */
	/* background-color: white; */
	/* padding-top: 16px; */
	margin-top: -20px;
}

.goods .breadcrumb .el-breadcrumb {
	width: 1225px;
	line-height: 30px;
	font-size: 12px;
	margin: 0 auto;
}

.brands .command-brands {
	background: linear-gradient(180deg, #fff6c8, #fff1a6 100%);
	border-radius: 10px;
	padding: 30px 0;
}

.brands .command-brands .logo {
	width: 200px;
	text-align: center;
	margin-top: 5px;
}

.brands .command-brands .logo div {
	width: 112px;
	height: 118px;
	background: url(../images/cmd-logo.png) no-repeat;
	background-size: 100%;
	margin: 0 auto;
}

.brands .command-brands .logo h3 {
	font-size: 18px;
	color: #ec9b3d;
	font-weight: 600;
}

.brands .command-brands .items {
	width: 960px;
	background: #ffffff;
	border-radius: 10px;
	box-shadow: 0px 20px 16px 0px rgb(185 168 63 / 10%);
	padding: 10px 0;
}

.brands .command-brands .items .item {
	width: 140px;
	height: 70px;
	text-align: center;
	padding: 10px 0;
	margin: 0 10px;
}

.brands .command-brands .items .item img {
	width: 100px;
	height: 50px;
}

.brands .commands-all {
	background-color: #fff;
	margin-top: 20px;
	padding: 0 25px;
	box-sizing: border-box;
}

.brands .commands-all .title-div {
	padding-top: 10px;
}

.brands .commands-all .title-div .title {
	color: #000;
	font-size: 18px;
	font-weight: 600;
	padding: 10px;
}

.brands .commands-all .brand-list {
	padding-bottom: 20px;
}

.brands .commands-all .brand-list .brand-one .items .item {
	width: 120px;
	margin: 10px 3px 0;
	text-align: center;
}

.brands .commands-all .brand-list .brand-one .items .item .img {
	width: 100px;
	height: 50px;
	border-radius: 5px;
	margin: 0 auto;
}

.brands .commands-all .brand-list .brand-one .items .item p {
	color: #8b8b8b;
	padding: 0 10px;
	height: 24px;
	line-height: 24px;
	padding-top: 3px;
}
</style>
